<template>
  <section>
    <div class="content-fluid" style="height: 100vh">
      <div class="row p-0 m-0">
        <div class="col-6 p-0 m-0 d-none d-md-block">
          <img
            src="@/assets/img/foto_login.png"
            style="height: 100vh; width: 100%"
          />
        </div>
        <div
          class="col-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center content-resp"
          style="background-color: #fff"
        >
          <div class="col-8">
            <img
              src="@/assets/img/logo-cerforc.svg"
              alt
              srcset
              class="img-form mb-3 img-fluid"
            />
            <form @submit.prevent="loginIn">
              <div class="form-group">
                <label for="exampleInputEmail1" class="m-0 font-weight-bold">
                  Usuario
                  <small>(cédula)</small>
                </label>
                <input
                  type="text"
                  class="form-control form-control-sm rounded"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  :class="errorCedula ? 'is-invalid' : ''"
                  v-model="cedula"
                />
                <div class="invalid-feedback d-block" v-if="errorCedula">
                  {{ errorMessageCedula }}
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="m-0 font-weight-bold"
                  >Contraseña</label
                >
                <input
                  type="password"
                  class="form-control form-control-sm rounded"
                  id="exampleInputPassword1"
                  :class="errorPassword ? 'is-invalid' : ''"
                  v-model="password"
                />
                <div class="invalid-feedback d-block" v-if="errorPassword">
                  {{ errorMessagePassword }}
                </div>
              </div>
              <div class="invalid-feedback d-block" v-if="errorPeticion">
                <p class="text-center font-weight-bold" style="font-size: 16px">
                  {{ errorMessagePeticion }}
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <p class="text-center col-8">
                  <span class="cursor-pointer" @click="recuperar"
                    >¿Olvidaste tu contraseña?</span
                  >
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-login btn-sm text-white"
                  type="submit"
                  v-if="!verificando"
                >
                  Ingresar
                </button>
                <button
                  class="btn btn-login btn-sm"
                  type="button"
                  disabled
                  v-else
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Loading...</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import _ from "lodash";
export default {
  name: "login",
  data() {
    return {
      cedula: "",
      password: "",
      errorMessagePeticion: "",
      errorPeticion: false,
      errorCedula: false,
      errorPassword: false,
      errorMessageCedula: "",
      errorMessagePassword: "",
      verificando: false,
    };
  },
  watch: {
    cedula() {
      if (this.cedula.length > 0) {
        this.errorCedula = false;
        this.errorMessageCedula = "";
        this.errorPeticion = false;
        this.errorMessagePeticion = "";
      }
    },
    password() {
      if (this.password.length > 0) {
        this.errorPassword = false;
        this.errorMessagePassword = "";
        this.errorPeticion = false;
        this.errorMessagePeticion = "";
      }
    },
  },
  methods: {
    loginIn() {
      if (this.validar()) {
        this.verificando = true;
        let datos = {
          cedula: this.cedula,
          password: this.password,
        };
        this.$store.dispatch("setLogin", datos).then((response) => {
          if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 2) {
            this.errorPeticion = true;
            this.verificando = false;
            this.errorMessagePeticion = response.MESSAGE;
          } else {
            if (parseInt(response.DATA.user.rol_id) == 4) {
              if (response.DATA.user.evaluar == 1) {
                this.$router.push({ name: "vocero" });
              } else {
                this.$router.push({ name: "mi-perfil" });
              }
            } else {
              if (parseInt(response.DATA.user.rol_id) == 2) {
                this.verificando = false;
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  onOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  },
                });

                Toast.fire({
                  icon: "info",
                  title: "El usuario no tiene acceso a la paltaforma.",
                });
              } else {
                this.verificando = false;
                this.$router.push({ name: "formadores" });
              }
            }
          }
        });
      }
    },
    validar() {
      let contador = 0;
      let valid_correo = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.cedula == "") {
        contador++;
        this.errorCedula = true;
        this.errorMessageCedula =
          "Debes ingresar su cedula para iniciar session.";
      }
      // if (!valid_correo.test(this.correo) && this.correo != "") {
      //   contador++;
      //   this.errorCorreo = true;
      //   this.errorMessageCorreo = "No es un correo valido.";
      // }
      if (this.password == "") {
        contador++;
        this.errorPassword = true;
        this.errorMessagePassword =
          "Debes ingresar una contraseña para iniciar session.";
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    recuperar() {
      this.$router.push("enviar_correo");
    },
  },
  beforeCreate() {
    if (localStorage.getItem("usuario")) {
      let usuario = JSON.parse(atob(localStorage.getItem("usuario")));
      switch (usuario.user.rol_id) {
        case 3:
          this.$router.replace("/formadores");
          break;
        case 4:
          this.$router.replace("/cliente/perfil");
          break;

        default:
          break;
      }
    } else {
      localStorage.removeItem("count");
    }
  },
};
</script>
<style lang="css">
@media (max-width: 768px) {
  .content-resp {
    height: 100vh;
  }
}
@media (max-width: 400px) {
  .btn-login {
    width: 100%;
    background-color: #132548 !important;
    color: #ffffff;
    -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
  }
}
</style>