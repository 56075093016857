<template>
  <div class="contenedor-login">
    <div class="row">
      <div class="col-6 p-0 m-0 d-none d-md-block">
        <img
          src="@/assets/img/foto_login.png"
          style="height: 100vh; width: 100%"
        />
      </div>
      <div
        class="col-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center align-items-center"
        style="height: 100vh"
      >
        <div class="col-lg-6">
          <img
            src="@/assets/img/logo-cerforc.svg"
            alt
            srcset
            class="img-form mb-3"
          />
          <form @submit.prevent="guardar">
            <div class="form-group">
              <label
                for="exampleInputEmail1"
                class="text-dark font-weight-bold m-0"
                >Contraseña</label
              >
              <input
                type="password"
                class="form-control form-control-sm rounded-0"
                id="input-password"
                aria-describedby="emailHelp"
                :class="errorPassword ? 'is-invalid' : ''"
                v-model="password"
              />
              <div class="invalid-feedback d-block" v-if="errorPassword">
                {{ errorPasswordMessage }}
              </div>
            </div>
            <div class="form-group">
              <label
                for="exampleInputEmail1"
                class="text-dark font-weight-bold m-0"
                >Repetir contraseña</label
              >
              <input
                type="password"
                class="form-control form-control-sm rounded-0"
                id="input-password-repit"
                aria-describedby="emailHelp"
                :class="errorRepitPassword ? 'is-invalid' : ''"
                v-model="repitpassword"
              />
              <div class="invalid-feedback d-block" v-if="errorRepitPassword">
                {{ errorRepitPasswordMessage }}
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <button class="btn btn-login btn-sm" type="submit" v-if="estado">
                Actualizar
              </button>
              <button
                class="btn btn-login btn-sm"
                type="button"
                disabled
                v-else
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Actualizando...</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "updatePass",
  data() {
    return {
      password: "",
      repitpassword: "",
      errorPassword: false,
      errorRepitPassword: false,
      errorRepitPasswordMessage: "",
      errorPasswordMessage: "",
      estado: true,
      error: false,
      errorToken: "",
    };
  },
  beforeCreate() {
    let posicion = this.$route.params.token.indexOf("=");
    let token = this.$route.params.token.substring(0, posicion + 1);
    this.$store
      .dispatch("verificarToken", { token: token })
      .then((response) => {
        if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
        } else {
          this.error = true;
          this.errorToken = response.MESSAGE;
        }
      });
  },
  watch: {
    password() {
      if (this.password != "") {
        this.errorPassword = false;
        this.errorPasswordMessage = "";
      }
    },
    repitpassword() {
      if (this.repitpassword != "") {
        this.errorRepitPassword = false;
        this.errorRepitPasswordMessage = "";
      }
    },
  },
  methods: {
    validar() {
      let contador = 0;
      if (this.password == "") {
        contador++;
        this.errorPassword = true;
        this.errorPasswordMessage = "Ingrese una contraseña.";
      }
      if (this.repitpassword == "") {
        contador++;
        this.errorRepitPassword = true;
        this.errorRepitPasswordMessage = "Ingrese una contraseña.";
      }
      if (this.password != "" && this.password != this.repitpassword) {
        contador++;
        this.errorPassword = true;
        this.errorPasswordMessage = "La contraseña no coinciden.";
      }
      if (this.repitpassword != "" && this.repitpassword != this.password) {
        contador++;
        this.errorRepitPassword = true;
        this.errorRepitPasswordMessage = "La contraseña no coinciden.";
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    guardar() {
      if (this.validar() && !this.validarToken()) {
        this.estado = false;
        let posicion = this.$route.params.token.indexOf("=");
        let token = this.$route.params.token.substring(0, posicion + 1);
        let correo = this.$route.params.token.substring(posicion + 2);
        let datos = {
          correo: correo,
          token: token,
          password: btoa(this.password),
        };
        this.$store.dispatch("updatePassword", datos).then((response) => {
          if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
            this.estado = true;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: "Se actualizo la contraseña!.",
            });
            this.$router.push({ name: "login" });
          } else if (
            _.has(response, "STATUS_CODE") &&
            response.STATUS_CODE == 2
          ) {
            this.estado = true;
            this.errorLogin = true;
            this.errorLoginMessage = response.MESSAGE;
          }
        });
      }
    },
    cancelar() {
      this.$router.push({ name: "login" });
    },
    validarToken() {
      if (this.error == true) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "info",
          title: this.errorToken,
        });
      }
      return this.error;
    },
  },
};
</script>
