<template>
  <section>
    <loading v-if="estadoloading" />
    <div class="row mt-3 justify-content-center" v-else>
      <div class="col-lg-2 d-flex align-items-center">
        <div
          class="rounded-circle border position-relative cursor-pointer"
          @click="open"
        >
          <div
            class="d-flex justify-content-center align-items-center"
            v-if="!cargando_foto"
          >
            <img
              src="@/assets/img/uploadfile.png"
              alt
              width="200"
              class="rounded-circle"
              v-if="foto == ''"
            />
            <img
              :src="url + foto"
              alt
              width="200"
              height="200"
              class="rounded-circle"
              v-else
            />
          </div>
          <div class="d-flex justify-content-center align-items-center" v-else>
            <div style="width: 200px; height: 200px">
              <div
                class="spinner-border"
                role="status"
                style="position: absolute; top: 40%; left: 40%"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <div class="add-foto">
            <p class="m-0">Agregar foto</p>
          </div>
          <input
            type="file"
            name="foto"
            id="foto"
            class="d-none"
            @change="uploadFile"
          />
        </div>
        <div
          class="position-absolute text-muted"
          style="bottom: 20%; left: 15%"
        >
          <small>dimensión max (500 x 500)</small>
        </div>
      </div>
      <div class="col-lg-4 ml-5">
        <form @submit.prevent="guardar">
          <div class="form-group m-0">
            <label class="m-0 p-0">Nombre *</label>
            <input
              type="text"
              class="form-control form-control-sm rounded-lg"
              :class="errorNombre ? 'border-danger' : ''"
              name="nombre"
              v-model="nombre"
            />
            <div class="invalid-feedback d-block" v-if="errorNombre">
              {{ messageErrorNombre }}
            </div>
          </div>
          <div class="form-group m-0">
            <label class="m-0 p-0">Apellido *</label>
            <input
              type="text"
              class="form-control form-control-sm rounded-lg"
              :class="errorApellido ? 'border-danger' : ''"
              v-model="apellido"
            />
            <div class="invalid-feedback d-block" v-if="errorApellido">
              {{ messageErrorApellido }}
            </div>
          </div>
          <div class="form-group m-0">
            <label class="m-0 p-0">Cédula *</label>
            <input
              type="text"
              class="form-control form-control-sm rounded-lg"
              :class="errorCedula ? 'border-danger' : ''"
              v-model="cedula"
            />
            <div class="invalid-feedback d-block" v-if="errorCedula">
              {{ messageErrorCedula }}
            </div>
          </div>
          <div class="form-group m-0">
            <label class="m-0 p-0">Profesión *</label>
            <input
              type="text"
              class="form-control form-control-sm rounded-lg"
              :class="errorProfesion ? 'border-danger' : ''"
              v-model="profesion"
            />
            <div class="invalid-feedback d-block" v-if="errorProfesion">
              {{ messageErrorProfesion }}
            </div>
          </div>
          <div class="form-group m-0">
            <label class="m-0 p-0">Especialidad *</label>
            <input
              type="text"
              class="form-control form-control-sm rounded-lg"
              v-model="especialidad"
              :class="errorEspecialidad ? 'border-danger' : ''"
            />
            <div class="invalid-feedback d-block" v-if="errorEspecialidad">
              {{ messageErrorEspecialidad }}
            </div>
          </div>
          <div class="form-group m-0">
            <label class="m-0 p-0">Numero de contacto *</label>
            <input
              type="text"
              class="form-control form-control-sm rounded-lg"
              :class="errorTelefono ? 'border-danger' : ''"
              v-model="telefono"
            />
            <div class="invalid-feedback d-block" v-if="errorTelefono">
              {{ messageErrorTelefono }}
            </div>
          </div>
          <div class="form-group">
            <label class="m-0 p-0">Correo electrónico *</label>
            <input
              type="text"
              class="form-control form-control-sm rounded-lg"
              :class="errorCorreo ? 'border-danger' : ''"
              v-model="correo"
            />
            <div class="invalid-feedback d-block" v-if="errorCorreo">
              {{ messageErrorCorreo }}
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button
              type="submit"
              class="btn btn-secondary btn-sm mr-1"
              @click="cancelar"
            >
              Cancelar
            </button>
            <button type="submit" class="btn btn-success btn-sm" v-if="estado">
              Guardar
            </button>
            <button
              class="btn btn-success btn-sm"
              type="button"
              disabled
              v-else
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Actulizando...
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import loading from "@/components/Loading";
export default {
  name: "formadores_editar",
  data() {
    return {
      estado: true,
      nombre: "",
      nombrefile: "",
      apellido: "",
      cedula: "",
      profesion: "",
      especialidad: "",
      telefono: "",
      correo: "",
      foto: "",
      id: "",
      errorNombre: false,
      messageErrorNombre: "",
      errorApellido: false,
      messageErrorApellido: "",
      errorCedula: false,
      messageErrorCedula: "",
      errorProfesion: false,
      messageErrorProfesion: "",
      errorEspecialidad: false,
      messageErrorEspecialidad: "",
      errorTelefono: false,
      messageErrorTelefono: "",
      errorCorreo: false,
      messageErrorCorreo: "",
      url: process.env.VUE_APP_RUTA_IMG,
      cargando_foto: false,
    };
  },
  watch: {
    nombre() {
      if (this.nombre.length > 0) {
        this.errorNombre = false;
        this.messageErrorNombre = "";
      }
    },
    apellido() {
      if (this.apellido.length > 0) {
        this.errorApellido = false;
        this.messageErrorApellido = "";
      }
    },
    cedula() {
      if (this.cedula.length > 0) {
        this.errorCedula = false;
        this.messageErrorCedula = "";
      }
    },
    profesion() {
      if (this.profesion.length > 0) {
        this.errorProfesion = false;
        this.messageErrorProfesion = "";
      }
    },
    especialidad() {
      if (this.especialidad.length > 0) {
        this.errorEspecialidad = false;
        this.messageErrorEspecialidad = "";
      }
    },
    telefono() {
      if (this.telefono.length > 0) {
        this.errorTelefono = false;
        this.messageErrorTelefono = "";
      }
    },
    correo() {
      if (this.correo.length > 0) {
        this.errorCorreo = false;
        this.messageErrorCorreo = "";
      }
    },
  },
  components: {
    loading,
  },
  async mounted() {
    await this.$store
      .dispatch("fetchFormador", this.$route.params.id)
      .then((response) => {
        if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
          const element = response.DATA;
          this.nombre = element.nombre;
          this.apellido = element.apellido;
          this.cedula = element.cedula;
          this.profesion = element.cargo;
          this.especialidad = element.especialidad;
          this.telefono = element.celular;
          this.correo = element.correo;
          this.foto = element.foto == null ? "" : element.foto;
          this.id = element.id;
        }
      });
  },
  computed: {
    estadoloading() {
      return this.$store.getters.getEstatusLoading;
    },
  },
  methods: {
    open() {
      $("#foto").click();
    },
    uploadFile(e) {
      let posicion = e.target.files[0].name.indexOf(".");
      let extension = e.target.files[0].name.substring(posicion + 1);
      let valido = ["png", "jpg", "jpeg"];
      let reader = new FileReader();
      let element = this;
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", function () {
        var image = new Image();
        image.src = reader.result;
        image.addEventListener("load", function () {
          if (image.width > 500 && image.height > 500) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "info",
              title: "La medida de la imagen debe ser de 500x500",
            });
            return false;
          }
          if (/\.(jpg|png|jpeg)$/i.test(e.target.files[0].name)) {
            element.cargando_foto = true;
            var formData = new FormData();
            formData.append("archivo", e.target.files[0]);
            formData.append(
              "token",
              element.$store.getters.getUserAuth.access_token
            );
            var request = new XMLHttpRequest();
            request.onreadystatechange = function () {
              if (request.readyState == 4) {
                if (request.status >= 300) {
                  console.log("Error");
                } else {
                  let result = JSON.parse(request.response);
                  if (result.STATUS_CODE == 1) {
                    element.foto = result.DATA.ruta;
                    element.nombrefile = result.DATA.nombre;
                    element.cargando_foto = false;
                  }
                }
              }
            };
            request.open(
              "POST",
              process.env.VUE_APP_WEBSERVICE_URL + "/uploadFoto"
            );
            // Send the request
            request.send(formData);
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "No es una imagen.",
            });
          }
        });
      });
    },
    guardar() {
      if (this.validar()) {
        this.estado = false;
        let params = {
          nombrefile: this.foto,
          nombre: this.nombre,
          apellido: this.apellido,
          cedula: this.cedula,
          celular: this.telefono,
          cargo: this.profesion,
          correo: this.correo,
          especialidad: this.especialidad,
          id: this.id,
        };

        params._method = "put";
        this.$store.dispatch("updateFormador", params).then((response) => {
          if (response.STATUS_CODE == 1) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Se actualizo el formador correctamente.",
            });
            this.limpiar();
            this.estado = true;
            this.$router.push({ name: "formadores" });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Error al actualizar el formador",
            });
            this.estado = true;
          }
        });
      }
    },
    cancelar() {
      this.$router.push({ name: "formadores" });
      this.limpiar();
    },
    limpiar() {
      this.nombre = "";
      this.apellido = "";
      this.cedula = "";
      this.telefono = "";
      this.profesion = "";
      this.correo = "";
      this.especialidad = "";
      this.id = "";
      this.foto = "";
      this.nombrefile = "";
      this.errorNombre = false;
      this.messageErrorNombre = "";
      this.errorApellido = false;
      this.messageErrorApellido = "";
      this.errorCedula = false;
      this.messageErrorCedula = "";
      this.errorProfesion = false;
      this.messageErrorProfesion = "";
      this.errorEspecialidad = false;
      this.messageErrorEspecialidad = "";
      this.errorTelefono = false;
      this.messageErrorTelefono = "";
      this.errorCorreo = false;
      this.messageErrorCorreo = "";
    },
    validar() {
      let contador = 0;
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var num = /[0-9]/;
      var letra = /[a-zA-Z]/;
      if (this.nombre == "") {
        contador++;
        this.errorNombre = true;
        this.messageErrorNombre = "Dejanos saber cuales tu nombre";
      }
      if (!re.test(this.correo)) {
        contador++;
        this.errorCorreo = true;
        this.messageErrorCorreo = "No es un correo valido";
      }
      if (num.test(this.nombre)) {
        contador++;
        this.errorNombre = true;
        this.messageErrorNombre = "El nombre no puede tener numeros";
      }
      if (this.apellido == "") {
        contador++;
        this.errorApellido = true;
        this.messageErrorApellido = "Dejanos saber cuales tu apellido";
      }
      if (num.test(this.apellido)) {
        contador++;
        this.errorApellido = true;
        this.messageErrorApellido = "El apellido no puede tener numeros";
      }
      if (this.cedula == "") {
        contador++;
        this.errorCedula = true;
        this.messageErrorCedula = "Cual es tu numero de documento";
      }
      if (this.profesion == "") {
        contador++;
        this.errorProfesion = true;
        this.messageErrorProfesion = "Dejanos saber cual es tu profesion";
      }
      if (num.test(this.profesion)) {
        contador++;
        this.errorProfesion = true;
        this.messageErrorProfesion = "Tu profesion no puede contener numero";
      }
      if (this.especialidad == "") {
        contador++;
        this.errorEspecialidad = true;
        this.messageErrorEspecialidad = "Dejanos saber cual es tu especialidad";
      }
      if (num.test(this.especialidad)) {
        contador++;
        this.errorEspecialidad = true;
        this.messageErrorEspecialidad = "Tu profesion no puede contener numero";
      }
      if (this.correo == "") {
        contador++;
        this.errorCorreo = true;
        this.messageErrorCorreo = "Dejanos saber cual es tu correo";
      }
      if (this.telefono == "") {
        contador++;
        this.errorTelefono = true;
        this.messageErrorTelefono = "Dejanos saber cual es tu telefono";
      }
      if (letra.test(this.telefono)) {
        contador++;
        this.errorTelefono = true;
        this.messageErrorTelefono = "El telefono no puede tener letra";
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
  },
};
</script>