<template>
  <div class="contenedor-login">
    <div class="row">
      <div class="col-6 p-0 m-0 d-none d-md-block">
        <img
          src="@/assets/img/foto_login.png"
          style="height: 100vh; width: 100%"
        />
      </div>
      <div
        class="col-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center align-items-center"
        style="height: 100vh"
      >
        <div class="col-6">
          <img
            src="@/assets/img/logo-cerforc.svg"
            alt
            srcset
            class="img-form mb-3"
          />
          <form @submit.prevent="enviar">
            <div class="form-group">
              <label
                for="exampleInputEmail1"
                class="text-dark font-weight-bold m-0"
                >Correo</label
              >
              <input
                type="text"
                class="form-control form-control-sm rounded-0"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                :class="errorCorreo ? 'is-invalid' : ''"
                v-model="correo"
              />
              <div class="invalid-feedback d-block" v-if="errorCorreo">
                {{ errorCorreoMessage }}
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <button
                class="btn btn-cancelar btn-sm mr-2"
                type="submit"
                :disabled="enviando"
                @click="$router.push({ name: 'login' })"
              >
                Cancelar
              </button>
              <button
                class="btn btn-login btn-sm text-white"
                type="submit"
                v-if="!enviando"
              >
                Enviar link
              </button>
              <button
                class="btn btn-login btn-sm"
                type="button"
                disabled
                v-else
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Enviando...</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "sen_email",
  data() {
    return {
      correo: "",
      enviando: false,
      errorCorreo: false,
      errorCorreoMessage: "",
    };
  },
  watch: {},
  methods: {
    validar() {
      let contador = 0;
      let valid_correo = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.correo == "") {
        contador++;
        this.errorCorreo = true;
        this.errorCorreoMessage =
          "Debes ingresar tu correo para enviar el link.";
      }
      if (!valid_correo.test(this.correo) && this.correo != "") {
        contador++;
        this.errorCorreo = true;
        this.errorCorreoMessage = "No es un correo valido.";
      }
      if (contador == 0) {
        return true;
      }
      return false;
    },
    enviar() {
      if (this.validar()) {
        this.enviando = true;
        this.$store
          .dispatch("sendEmail", { email: this.correo })
          .then((response) => {
            if (_.has(response, "STATUS_CODE") && response.STATUS_CODE == 1) {
              this.enviando = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Se envio el link.",
              });
            } else {
              this.enviando = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: response.MESSAGE,
              });
            }
          });
      }
    },
  },
};
</script>